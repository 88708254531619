@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "sofia-pro", sans-serif !important;
  font-weight: 500;
  color: #15233c;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  align-items: stretch;
}

.sofia-pro-l {
  font-family: "sofia-pro", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.sofia-pro-r {
  font-family: "sofia-pro", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.sofia-pro-m {
  font-family: "sofia-pro", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.sofia-pro-s {
  font-family: "sofia-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.sofia-pro-b {
  font-family: "sofia-pro", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.bg-blue-grad {
  background: #effafb;
  border-radius: 50px;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.scribble {
  position: absolute;
  top: 3px;
  width: 100%;
  height: 100%;
  background-image: url(/scribble.svg);
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.underline-blue:after {
  content: "";
  position: absolute;
  background-image: url(/underline.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
}

.blog-scribble-orange:after {
  content: "";
  position: absolute;
  background-image: url(/blog-scribble-orange.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 2px;
  padding-bottom: 6px;
  left: 0;
  bottom: 0;
}

.blog-scribble-pink:after {
  content: "";
  position: absolute;
  background-image: url(/blog-scribble-pink.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 2px;
  padding-bottom: 5px;
  left: 0;
  bottom: 0;
}

.blog-scribble-blue:after {
  content: "";
  position: absolute;
  background-image: url(/blog-scribble-blue.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 2px;
  padding-bottom: 6px;
  left: 0;
  bottom: 0;
}

.nav-shadow {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.bg-partner {
  background: linear-gradient(180deg, #f5f9fa 0%, rgba(245, 249, 250, 0) 100%);
}

.ScrollbarsCustom-Scroller {
  overflow-x: scroll !important;
}

.ScrollbarsCustom-TrackX {
  max-width: 60rem !important;
  margin: 0 auto !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background-color: #f6f6f6 !important;
  height: 4px !important;
  width: 100% !important;
  transition-property: color, background-color, height, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.ScrollbarsCustom-TrackX:hover {
  transition-property: color, background-color, height, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  max-width: 60rem !important;
  margin: 0 auto !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background-color: #f6f6f6 !important;
  height: 8px !important;
  width: 100% !important;
}

.ScrollbarsCustom-ThumbX {
  background-color: #15233c20 !important;
}

video {
  outline: none;
  border: none;
}

.dot {
  width: 23px;
  height: 4px;
  background: #c9ccd1;
  border-radius: 3px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: #2589bd;
}

.shadow-landing {
  background: linear-gradient(180deg, rgba(246, 249, 250, 0) 0%, #f6f9fa 100%);
}

.shadow-landing-about {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}

.shadow-blue {
  background: linear-gradient(180deg, #f5f9fa 0%, rgba(245, 249, 250, 0) 100%);
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper .swiper-slide {
  height: 300px;
  line-height: 500px;
}

.dot-reviews {
  border: 1px #424b5b70 solid !important;
}

.text-underline-landing-about {
  position: relative;
  background-image: url(/underline-landing-about.svg);
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.underline-pink {
  position: relative;
  background-image: url(/underline-pink.svg);
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.text-underline-blue {
  position: relative;
  background-image: url(/underline-blue.svg);
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.text-underline-blue-home {
  position: relative;
  background-image: url(/underline-blue-home.svg);
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.text-underline-blue-claim {
  position: relative;
  background-image: url(/underline-blue-claim.svg);
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.text-underline-blue-light {
  position: relative;
  background-image: url(/scribble-blue-light.svg);
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.blue-bg {
  background: linear-gradient(180deg, #ffffff 0%, #92d0f2 58.39%);
}

.coloredText span {
  color: #298dcd;
}

.tgf-landing {
  background: linear-gradient(180deg, #dbf3ff 0%, rgb(255, 255, 255) 100%);
}

.accordion-last div:nth-last-child(1) > div:nth-child(1) > div {
  border-bottom: 0 !important;
}

.accordion-last-2 div:nth-last-child(1) {
  border-bottom: 0 !important;
}

.blog-post p {
  font-size: 18px;
  color: #424b5b;
  font-family: "sofia-pro", sans-serif;
  font-weight: 400;
}

.ellipsis-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ellipsis-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.thumbnail .keen-slider__slide {
  font-size: 30px;
  margin-top: 10px;
  background: rgb(64, 76, 255);
  background: linear-gradient(
    128deg,
    rgba(64, 76, 255, 1) 0%,
    rgba(174, 63, 255, 1) 100%
  );
  height: 100px;
}

.thumbnail .keen-slider__slide {
  cursor: pointer;
  background: rgb(64, 76, 255);
  background: linear-gradient(
    128deg,
    rgba(64, 76, 255, 1) 0%,
    rgba(174, 63, 255, 1) 100%
  );
}

.thumbnail .keen-slider__slide.active {
  border: 2px solid #2589bd;
}

.image-container {
  width: 100%;
  position: relative;
  height: inherit;
}

.image-contain > span {
  position: unset !important;
  width: fit-content;
}

.image {
  object-fit: contain;
  width: 100% !important;
  position: absolute !important;
  height: unset !important;
}

.keen-slider:not([data-keen-slider-disabled]) {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  align-content: flex-start;
  display: flex;
  flex-direction: row;
  /* overflow: hidden; */
  position: relative;
  /* touch-action: pan-y; */
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  width: 100%;
}

.thumbnail .keen-slider__slide {
  font-size: 30px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  height: 100px;
  cursor: pointer;
  background: rgb(255, 255, 255);
}

.thumbnail {
  display: flex !important;
  overflow: hidden !important;
  flex-direction: column !important;
  transform: translate3d(0px, 0px, 0px) !important;
}

.keen-thumb {
  transform: translate3d(0px, 0px, 0px) !important;
}

.thumbnail .keen-slider__slide {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transform: translate3d(0px, 0px, 0px) !important;
  border-radius: 8px !important;
  max-height: 45px !important;
  min-height: 45px !important;
  max-width: 45px !important;
  min-width: 45px !important;
}

.text-grey {
  color: #15233c80;
}

.bg-blue-green-gradient {
  background: linear-gradient(270deg, #2589bd 0%, rgba(131, 176, 82, 0.5) 100%);
}

.text-light-blue {
  color: #2589bd;
}

.bg-active {
  background-color: #83b0521a !important;
}

.nav-hamburger {
  width: 30px;
  height: 26px;
  position: relative;
  cursor: pointer;
  margin-top: 11px;
}

.nav-hamburger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 24px;
  background: #15233c;
  opacity: 1;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.nav-hamburger span:nth-child(1) {
  top: 6px;
}

.nav-hamburger span:nth-child(2) {
  top: 12px;
}

.nav-hamburger-open span:nth-child(1) {
  opacity: 0;
  transform: scale(0.5, 0.5);
}

.nav-hamburger-open span:nth-child(2) {
  transform: translateY(-6px) rotate(45deg);
}

.nav-hamburger-open span:nth-child(3) {
  transform: translateY(6px) rotate(-45deg);
}

.lock-scroll {
  overflow: hidden;
}

.share-bg {
  background: linear-gradient(180deg, #d7f1ff 0%, #f9f9f9 100%);
}

.footer_decorator {
  display: block;
  background-color: white;
  border-radius: 30% 30% 0 0;
}

.forest-bound-color {
  color: #6c9051;
}

.alarm-color {
  color: #ed0006;
}

.bg-cold-snow {
  background: #fff4fe;
}

.bg-clear-skies {
  background-color: #ebf8ff;
}

.webkit-fill {
  min-height: env(safe-area-inset-bottom);
}

.pdp-description h3 {
  margin-top: 15px;
  font-family: "sofia-pro", sans-serif;
  font-weight: 600;
}

.pdp-description ul {
  position: relative;
  margin-top: 5px;
  font-family: "sofia-pro", sans-serif;
  font-weight: 500;
  list-style-type: disc;
  list-style-position: inside;
  width: 100%;
}

.pdp-description ul li {
  background-image: url(/blue-circle.svg);
  background-position: 16px 10px;
  background-size: 7px;
  background-repeat: no-repeat;
  padding-left: 35px;
  list-style: none;
  line-height: normal;
}

#reviews-io-polaris-modal {
  height: 0 !important;
}

.discount-underline {
  position: relative;
  background-image: url(/discount-scribble.svg);
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.checked-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 11px;
  font-size: 16px;
}

.checked-list-item p {
  margin-bottom: 0;
  font-size: inherit;
}

.checked-list-item:before {
  content: "";
  background: url("/checkInBlueRound.svg") no-repeat left center;
  height: 21px;
  width: 21px !important;
  display: block;
  padding-right: 35px;
}

.about-background {
  position: relative;
}

.about-background:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}

.yotpo-head {
  display: none !important;
}
.yotpo-sr-bottom-line-text {
  font-size: 12px !important;
}

.reviews .yotpo-bottomline-container {
  margin: 0 !important;
}

.reviews .yotpo-seo-page-layout {
  margin: 0;
}

.reviews .yotpo-seo-page-tabs {
  display: none;
}

.reviews .yotpo-bottomline-container {
  justify-content: flex-start !important;
}
.reviews .yotpo-bottom-line-summary {
  margin: 0 !important;
}
.reviews .yotpo-bottom-line {
  margin: 0 auto 0 0 !important;
}
.reviews .yotpo-border-smooth {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .watch-good-product .yotpo-sr-bottom-line-text {
    color: white !important;
  }
}

.product-paragraph-border-b > div:not(:nth-child(4)) {
  @apply border-b;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

div.acsb-trigger.acsb-ready {
  display: none !important;
}

.animation-opacity {
  animation: fadeIn 0.5s linear;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.watch-slider .swiper-slide {
  @apply relative mx-2.5 h-[174px] w-[98px] min-w-[98px] cursor-pointer overflow-hidden rounded-[8px] border transition-all hover:opacity-70 sm:h-[348px] sm:w-[196px] sm:min-w-[196px];
}

.watch-slider .swiper-slide .play-icon {
  @apply absolute left-1/2 top-1/2 z-10 h-auto w-3/12 -translate-x-1/2 -translate-y-1/2 transition-all;
}

.watch-slider .swiper-slide:hover .play-icon {
  @apply scale-125;
}

.video-fluid {
  height: 100%;
}

.lazyload-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-modal {
  height: 100%;
  padding-top: 0 !important;
}

.video-modal .bitmovinplayer-container:not([data-fullscreen="true"]) video {
  object-fit: contain !important;
}

.video-modal .ui-player-container {
  height: 100% !important;
}

.video-fluid .ui-player-container.bitmovinplayer-container {
  min-height: auto !important;
  min-width: auto !important;
  height: 100% !important;
}

.video-fluid .bmpui-container-wrapper {
  display: none;
}

.popup-wrapper {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(270deg, #2589bd 0%, #c1d7a8 100%);
  box-shadow: 0 10px 40px rgba(37, 137, 189, 0.5);
}

.try-form {
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 121.86%
  );
}

.simple-form .field-group {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 14px 0;
}

.simple-form .field-group label {
  width: 48%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .simple-form .field-group label {
    width: 100%;
  }
}

.simple-form .error-message {
  color: red;
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 12px;
  transform: translate(0, 100%);
}

.simple-form .server-error {
  border: 1px solid red;
  padding: 10px;
  background: rgba(255, 0, 0, 0.1);
  color: red;
  font-size: 14px;
  text-align: center;
  margin: 10px 0 0 0;
}

.simple-form label {
  font-size: 18px;
  position: relative;
  display: block;
}

.simple-form label span {
  margin-bottom: 8px;
}

.simple-form .product-radio input:checked ~ div {
  border: 1px solid #2589bd;
}

.simple-form .radio {
  display: block;
  margin-bottom: 12px;
}

.simple-form .radio input {
  width: auto;
  margin-right: 10px;
}

.simple-form .checkbox {
  margin-bottom: 20px;
  display: block;
}

.simple-form .checkbox input {
  width: auto;
  margin-right: 10px;
}

.simple-form input[type="text"],
.simple-form input[type="date"],
.simple-form input[type="email"] {
  border: 1px solid #d7e1e4;
  font-size: 18px;
  padding: 8px 14px;
  border-radius: 8px;
  width: 100%;
  transition: 0.3s linear;
}

.simple-form input:hover,
.simple-form input:focus {
  border: 1px solid #2589;
}

.simple-form input::placeholder {
  color: #a7a9ac;
}

.simple-form hr {
  height: 2px;
  background: #15233c;
  opacity: 20%;
  width: 100%;
  margin: 26px 0;
}

.simple-form-success {
  background: linear-gradient(360deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

.text-blue-gradient {
  background: linear-gradient(90deg, #15233c 0.95%, #2589bd 100.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.animate {
  animation: fadeIn 0.5s linear;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.custom-container {
  max-width: 1312px;
  margin: 0 auto;
  padding: 0 16px;
}

.gradient-facts {
  background: linear-gradient(#f9f9f9, #f9f9f900);
}
.gradient-facts-revert {
  background: linear-gradient(#f9f9f900, #ffffff);
}
